import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import GalleryTemplate from '../templates/pages/gallery';

const GalleryPage = ({ data, pageContext }) => {
  const structuredData = {
    ...data.pagesJson.languages.bg,
    gallery: data.pagesJson.gallery,
  };

  return (
    <Layout lang={pageContext.langKey}>
      <SEO
        title={structuredData.metaData.title}
        description={structuredData.metaData.description}
      />

      <GalleryTemplate data={structuredData} lang="bg" />
    </Layout>
  );
};

export const GALLERY_PAGE_BG_QUERY = graphql`
  query GALLERY_PAGE_BG_QUERY {
    pagesJson(pageKey: { eq: "gallery" }) {
      languages {
        bg {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
        }
      }
      gallery {
        file {
          childImageSharp {
            fluid(maxWidth: 692, maxHeight: 692) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default GalleryPage;
